<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>商品管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>商品审核</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('1')">
          <span>商品分类</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>新增商品分类</span>
        </div>
        <div class="date-search">
          <el-button type="primary" @click="add">添加部位</el-button>
          <el-button type="primary" @click="remove">删除部位</el-button>
        </div>
        <div class="mt-20">
          <div class="knowledge-from">
            <div class="from-item">
              <span>分类名称<span class="txt-red">*</span>：</span>
              <div class="from-item-input">
                <input type="text" v-model="fromList.name" />
              </div>
            </div>
            <div class="from-item">
              <span>封面图<span class="txt-red">*</span>：</span>
              <div class="from-item-upload">
                <el-upload
                  ref="fmupload"
                  class="upload-demo"
                  :action="$store.state.httpUrl + 'add/type'"
                  name="image"
                  :data="fromList"
                  list-type="picture"
                  :auto-upload="false"
                  :multiple="false"
                  :headers="uploadHeaders"
                  :on-success="uploadSuccess"
                  :on-change="uploadfmtChange"
                  :limit="1"
                  :on-exceed="uploadExceed"
                  :http-request="fmuploadFile"
                  :on-remove="uploadfmRemove"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <span class="ml-50 txt-red">请上传正方形图片</span>
                </el-upload>
              </div>
            </div>
            <div v-for="(item, index) in fromList.files" :key="index">
              <div class="from-item">
                <span>部位名称<span class="txt-red">*</span>：</span>
                <div class="from-item-input">
                  <input
                    type="text"
                    v-model="fromList.files[index].files_name"
                  />
                </div>
              </div>
              <!-- :auto-upload="false" -->
              <div class="from-item">
                <span>示意图<span class="txt-red">*</span>：</span>
                <div class="from-item-upload">
                  <el-upload
                    ref="upload"
                    class="upload-demo"
                    :action="'url'"
                    name="file"
                    :data="fromList"
                    list-type="picture"
                    :auto-upload="false"
                    :multiple="false"
                    :headers="uploadHeaders"
                    :on-change="
                      (file, fileList) => {
                        uploadChange(file, fileList, index);
                      }
                    "
                    :limit="1"
                    :on-exceed="uploadExceed"
                    :http-request="uploadFile"
                    :on-remove="
                      (file, fileList) => {
                        uploadRemove(file, fileList, index);
                      }
                    "
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <span class="ml-50 txt-red">请上传正方形图片</span>
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="from-item">
              <el-button type="primary" @click="submitUpload">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      fromList: {
        name: "",
        image: "",
        files: [
          {
            files_name: "",
            files: [],
          },
        ],
      },
      uploadHeaders: {
        "Remember-Token": localStorage.getItem("rememberToken"),
      },
      formDate: "",
    };
  },
  created() {},
  methods: {
    uploadfmRemove() {
      this.fromList.image = [];
    },
    uploadRemove(file, fileList, index) {
      this.fromList.files[index].files = [];
    },
    fmuploadFile(file) {
      this.formDate.append("image", file.file);
      // console.log(this.formDate);
    },
    uploadFile(file) {
      this.formDate.append("files[]", file.file);
      // console.log(this.formDate);
    },
    add() {
      this.fromList.files.push({
        files_name: "",
        files: [],
      });
    },
    remove() {
      if (this.fromList.files.length == 1) {
        this.$notify({
          title: "提示",
          message: "至少保留一条！",
          type: "warning",
        });
      } else {
        this.fromList.files = this.fromList.files.slice(0, -1);
      }
    },
    uploadfmtChange(file, fileList) {
      console.log(file, fileList);
      this.fromList.image = "";
      this.fromList.image = URL.createObjectURL(file.raw);
      console.log(this.fromList.image);
    },
    uploadChange(file, fileList, index) {
      // console.log(file, fileList, index);
      this.fromList.files[index].files = [];
      this.fromList.files[index].files.push(file.raw);
      // console.log(this.fromList.files[index]);
    },
    uploadExceed() {
      this.$notify({
        title: "警告",
        message: "只可选择一张图片",
        type: "warning",
      });
    },
    uploadSuccess(response, file, fileList) {
      if (response.status == 200) {
        this.$notify({
          title: "提示",
          message: "上传成功！",
          type: "success",
        });
        this.$router.push("/knowledge");
      } else if (response.status == 400) {
        this.$notify.error({
          title: "提示",
          message: response.message,
        });
        file.status = "fail";
      }
      console.log(response, file, fileList);
    },
    submitUpload() {
      let _this = this;
      if (this.fromList.name == "") {
        this.$notify.error({
          title: "提示",
          message: "分类名称不可为空！",
        });
        return false;
      }
      if (this.fromList.image == "") {
        this.$notify.error({
          title: "提示",
          message: "请上传封面图！",
        });
        return false;
      }
      for (let i = 0; i < this.fromList.files.length; i++) {
        // console.log(this.fromList.files[i].files);
        if (this.fromList.files[i].files_name == "") {
          this.$notify.error({
            title: "提示",
            message: "请填写部位名称！",
          });
          return false;
        }
        if (this.fromList.files[i].files.length == 0) {
          this.$notify.error({
            title: "提示",
            message: "请上传示意图！",
          });
          return false;
        }
      }
      this.formDate = new FormData();
      console.log(_this.$refs);
      _this.$refs.fmupload.submit();
      for (let i = 0; i < this.fromList.files.length; i++) {
        _this.$refs.upload[i].submit();
        this.formDate.append("files_name[]", this.fromList.files[i].files_name);
      }
      this.formDate.append("name", this.fromList.name);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.$axios
        .post("http://weixin.maibaobao.shop/api/add/category", this.formDate, config)
        .then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.$router.push("/classification");
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
          // console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/commodity") {
        this.$router.push("/commodity");
      } else if (index == 1) {
        this.$router.push("/classification");
      } else {
        this.reload();
      }
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss">
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .knowledge-from {
    margin-top: 30px;
    padding-left: 150px;
    .from-item {
      margin-top: 50px;
      .ql-container {
        height: 200px;
      }
      .from-item-select {
        display: inline-block;
        margin-left: 30px;
      }
      .from-item-upload {
        vertical-align: top;
        display: inline-block;
        margin-left: 30px;
      }
      .from-item-input {
        display: inline-block;
        width: 200px;
        border-bottom: 1px solid #bbb5b5;
        margin-left: 30px;
        input {
          width: 190px;
          border: 0px;
          outline: none;
        }
      }
    }
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      padding: 12px 30px;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
